$idsk-assets-path: '../../../node_modules/@id-sk/frontend/idsk/assets/';
$idsk-fonts-path: '../../../node_modules/@id-sk/frontend/govuk/assets/fonts/';
$govuk-assets-path: '../../../node_modules/@id-sk/frontend/govuk/assets/';

@import '@id-sk/frontend/idsk/all';
@import 'normalize.css';
@import '../../includes/components/pagination/paginations.scss';
@import 'accessible-autocomplete';
@import './modal.scss';
@import './autocomplete.scss';
@import './fixes.scss';
@import './searchAgent.scss';
@import './searchResults.scss';
@import './utilities.scss';
@import './suitableCandidates.scss';

//Dokumentácia SCSS
@import '../../dokumentacia/assets/styles/app.scss';

//Homepage search section
.vpm-main-searchbar-padding {
  padding-top: govuk-spacing(6);
  padding-bottom: govuk-spacing(6);
  padding-left: govuk-spacing(2);
}
