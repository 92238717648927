.idsk-pagination-container {
  @include govuk-responsive-margin(6, 'bottom');
}

.idsk-pagination-prev a,
.idsk-pagination-next a {
  text-decoration: none;
  background-repeat: no-repeat;
  background-size: 17px 14px;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.idsk-pagination-next {
  text-align: right;
  a {
    padding-right: 30px;
    background-image: url('../icons/arrow-pagination-right.svg');
    background-position: 100% 4px;
  }
}

.idsk-pagination-prev {
  a {
    padding-left: 30px;
    background-image: url('../icons/arrow-pagination-left.svg');
    background-position: 0 4px;
  }
}

.idsk-pagination-list {
  margin: 0 auto;

  ul {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0;
    @include govuk-responsive-margin(3, 'left');
    @include govuk-responsive-margin(3, 'right');

    @include mq($until: mobile) {
      @include govuk-responsive-margin(6, 'top');
    }

    > li {
      padding-left: 0;

      padding: 4px 12px;
      line-height: 20px;

      > a {
        text-decoration: none;

        &:hover,
        &:focus {
          text-decoration: underline;
          background: transparent;
        }

        &:focus {
          background: $govuk-focus-colour;
          outline: 3px solid $govuk-focus-colour;
          outline-offset: 0;
        }
      }
    }

    li.active {
      padding: 4px 12px;
      color: $govuk-border-colour;
    }

    > .active {
      span,
      a {
        color: $govuk-border-colour;
        cursor: default;
        background: transparent;
      }

      a {
        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }
  }
}
