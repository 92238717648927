/* -------------------------------------------------------------------------- *\
 * Necessary styling for the dialog to work
 * -------------------------------------------------------------------------- */

/**
 * When `<dialog>` is properly supported, the overlay is implied and can be
 * styled with `::backdrop`, which means the DOM one should be removed.
 */
[data-a11y-dialog-native] .dialog-overlay {
  display: none;
}

/**
     * When `<dialog>` is not supported, its default display is `inline` which can
     * cause layout issues.
     */
dialog[open] {
  display: block;
}

.dialog[aria-hidden='true'] {
  display: none;
}

/* -------------------------------------------------------------------------- *\
     * Styling to make the dialog look like a dialog
     * -------------------------------------------------------------------------- */

.dialog:not([data-a11y-dialog-native]),
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.dialog {
  display: flex;
  flex-direction: column;
}

.dialog-overlay {
  background-color: rgba(43, 46, 56, 0.9);
}

dialog::backdrop {
  background-color: rgba(43, 46, 56, 0.9);
}

.dialog-content {
  background-color: rgb(255, 255, 255);
  margin: auto;
  z-index: 2;
  position: relative;
}

dialog.dialog-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}

/* -------------------------------------------------------------------------- *\
     * Extra dialog styling to make it shiny
     * -------------------------------------------------------------------------- */

@keyframes fade-in {
  from {
    opacity: 0;
  }
}

@keyframes slide-up {
  from {
    transform: translateY(10%);
  }
}

.dialog-overlay {
  animation: fade-in 200ms both;
}

.dialog-content {
  animation: fade-in 400ms 200ms both, slide-up 400ms 200ms both;
}

dialog.dialog-content {
  animation: fade-in 400ms 200ms both;
}

.dialog-content {
  max-width: 90%;
  width: 600px;
  border-radius: 2px;
}

.dialog h1 {
  margin: 0;
  font-size: 1.25em;
}

.dialog-close {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  border: 0;
  padding: 0;
  background-color: transparent;
  font-weight: bold;
  font-size: 1.25em;
  width: 1.2em;
  height: 1.2em;
  text-align: center;
  cursor: pointer;
  transition: 0.15s;
}

@media screen and (min-width: 700px) {
  .dialog-close {
    top: 1em;
    right: 1em;
  }
}
